angular.module("UI8.components").component("searchModal", {
  template: `<div class="search">
      <div class="search__head">
        <div class="search__container container">
          <div class="search__field">
            <input class="search__input" ng-model="$root.searchTerm" ng-change="$ctrl.onSearchInput()" ng-keydown="$ctrl.watchKeys($event)" id="search-input" type="search" placeholder="Type anything to search..." name="search" autocomplete="off" maxlength="20" ui8-no-special-chars />
            <div class="search__value">
              <span>{{ $root.searchTerm }}</span>
              <button ng-if="$root.searchTerm" ng-click="$ctrl.clearSearch()">
                <svg viewBox="0 0 14 14"><path d="M1.988.585l.094.083L7 5.585 11.918.668a1 1 0 0 1 1.497 1.32l-.083.094L8.414 7l4.918 4.918a1 1 0 0 1-1.32 1.497l-.094-.083L7 8.414l-4.918 4.918a1 1 0 0 1-1.497-1.32l.083-.094L5.585 7 .668 2.082A1 1 0 0 1 1.886.515l.101.069z"/></svg>
              </button>
            </div>
          </div>
          <button class="search__close" ng-click="$ctrl.closeSearch()">
            <svg viewBox="0 0 14 14"><path d="M1.988.585l.094.083L7 5.585 11.918.668a1 1 0 0 1 1.497 1.32l-.083.094L8.414 7l4.918 4.918a1 1 0 0 1-1.32 1.497l-.094-.083L7 8.414l-4.918 4.918a1 1 0 0 1-1.497-1.32l.083-.094L5.585 7 .668 2.082A1 1 0 0 1 1.886.515l.101.069z"/></svg>
          </button>
          <button class="search__sale toggle" ng-class="{ active: $ctrl.seasonalSale }" ng-click="$ctrl.toggleSale()">On Sale</button>
          <div class="search__filters filters">
            <div class="filters__container">
              <div class="filters__categories">
                <div class='filters__btns' on-load='$ctrl.changeHandler()'>
                  <button class='filters__btn' ng-repeat='category in $ctrl.categoryList track by category.slug' ng-click='$ctrl.updateCategoryFilter(category.slug)' ng-class='$ctrl.isCategoryActive(category.slug)'>
                    {{ ::category.name }}
                  </button>
                </div>
              </div>
              <div class="filters__select">
                <div class="filters__head">
                  <div class="filters__label">Format</div>
                  <div class="filters__arrow">
                    <img ng-src="/img/app-icons/{{$ctrl.isAppActiveSlug()}}.svg" alt="Select" />
                  </div>
                </div>
                <div class="filters__dropdown isWiderest">
                  <button class="filters__btn" ng-repeat="app in $ctrl.appList track by app.slug" ng-click="$ctrl.updateSoftwareFilter(app.name)" ng-class="$ctrl.isAppActive(app.name)">
                    <img ng-src="/img/app-icons/{{::app.slug}}.svg" alt="{{ ::app.name }}" />
                    <span>{{ app.new_name || app.name }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search__body">
        <div class="search__container container">
          <div class="search__empty" ng-if="$ctrl.empty">
            <div class="search__alert">
              <div class="search__title">No results...</div>
              <div class="search__text">We couldn’t find any results based on your search terms. Adjust your parameters and try again.</div>
            </div>
            <ul class="search__placeholders"><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
          </div>
          <div class="search__catalog catalog" ng-if="!$ctrl.empty">
            <product-card ng-repeat="product in $ctrl.products track by product._id + '_' + $index" product='product' user='$ctrl.user'></product-card>
          </div>
          <div class="loader" ng-class="{ loading: $ctrl.searching, faded: !$ctrl.loadingMore && !$ctrl.searching }" ui8-lazy-load when-visible="$ctrl.loadMore()" disable="$ctrl.disabled" container="$ctrl.container">
        </div>
      </div>
    </div>`,

  controller: function ($rootScope, $location, ProductService, HistoryService) {
    var ctrl = this;
    var productCount = 24;
    var keywords = /(sketch|ui kit|wireframe kit|theme|icon|font|illustration|freebie)(s)?\:(\s)?/gi;
    var $searchInput;

    ctrl.appList = [
      {
        name: "Any Platform",
        slug: "any-prog",
        alt_name: "Any Software",
        new_name: "Any format",
      },
      {
        name: "Figma",
        slug: "figma-prog",
      },
      {
        name: "Sketch",
        slug: "sketch-prog",
      },
      {
        name: "XD",
        slug: "xd-prog",
      },
      {
        name: "Framer",
        slug: "framer-prog",
      },
      {
        name: "Cinema 4D",
        slug: "c4d-prog",
      },
      {
        name: "Blender",
        slug: "blender-prog",
      },
      {
        name: "3D Studio Max",
        slug: "3ds-prog",
      },
      {
        name: "Photoshop",
        slug: "ps-prog",
      },
      {
        name: "After Effects",
        slug: "ae-prog",
      },
      {
        name: "HTML",
        slug: "html-prog",
        new_name: "HTML/CSS",
      },
      {
        name: "Bootstrap",
        slug: "bootstrap-prog",
        new_name: "Bootstrap",
      },
      {
        name: "Keynote",
        slug: "keynote-prog",
      },
      {
        name: "PowerPoint",
        slug: "powerpoint-prog",
      },
      {
        name: "Illustrator",
        slug: "ai-prog",
      },
      {
        name: "Procreate",
        slug: "procreate-prog",
      },
      {
        name: "Xcode",
        slug: "xcode-prog",
      },
      {
        name: "Swift",
        slug: "swift-prog",
      },
      {
        name: "Spline",
        slug: "spline-prog",
      },
      {
        name: "React",
        slug: "react-prog",
      },
      {
        name: "InDesign",
        slug: "aid-prog",
      },
      {
        name: "Android",
        slug: "android-prog",
      },
    ];

    ctrl.categoryList = [
      {
        name: "All Products",
        slug: "all",
      },
      {
        name: "UI Kits",
        slug: "ui-kits",
      },
      {
        name: "Coded Templates",
        slug: "coded-templates",
      },
      {
        name: "3D Assets",
        slug: "3d-assets",
      },
      {
        name: "Wireframe Kits",
        slug: "wireframe-kits",
      },
      {
        name: "Icons",
        slug: "icons",
      },
      {
        name: "Fonts",
        slug: "fonts",
      },
      {
        name: "Illustrations",
        slug: "illustrations",
      },
      {
        name: "Themes",
        slug: "themes",
      },
      // {
      //   name: "Freebies",
      //   slug: "freebies",
      // },
    ];

    ctrl.products = [];
    ctrl.resultsCount = 0;
    ctrl.disabled = false;
    ctrl.searching = false;
    ctrl.loadingMore = false;
    ctrl.requestNumber = 1;
    ctrl.empty = false;
    ctrl.seasonalSale = false;
    ctrl.searchInputValue = "";

    ctrl.$onInit = function () {
      $searchInput = $("#search-input");

      if ($(window).width() < 768) {
        $searchInput.attr("placeholder", "Type to search...");
      }

      ctrl.user = $rootScope.user;
      updateCategoryFilter($rootScope.categoryFilter || "all");
      updateAppFilter($rootScope.appFilter);
      $searchInput.focus();
      if ($rootScope.searchTerm == null) {
        $rootScope.searchTerm = restoreSearchTerm();
      }
      ctrl.page = getPageNumber();

      ctrl.container = $(".search");

      runSearch(1, ctrl.page);

      function isGoingToProduct(oldPath, oldUrl) {
        return oldUrl.includes("?search=1");
      }

      HistoryService.rememberScrollPosition(
        isGoingToProduct,
        "search",
        function () {
          return document.querySelector(".search").scrollTop;
        }
      );
    };

    ctrl.isAppActive = function (appName) {
      return appName === $rootScope.appFilter ? "active" : "";
    };

    ctrl.isAppActiveSlug = function () {
      return ctrl.appList.find((filter) => filter.name === $rootScope.appFilter)
        .slug;
    };

    ctrl.isCategoryActive = function (categorySlug) {
      return categorySlug === $rootScope.categoryFilter ? "active" : "";
    };

    ctrl.updateSoftwareFilter = function (appName) {
      updateAppFilter(appName);
      runSearch(1);
    };

    ctrl.updateCategoryFilter = function (categorySlug) {
      updateCategoryFilter(categorySlug);
      runSearch(1);
    };

    const debouncedRunSearch = debounce(() => runSearch(1), 500);
    ctrl.onSearchInput = function () {
      debouncedRunSearch();
    };

    ctrl.clearSearch = function () {
      $rootScope.searchTerm = "";
      updateSearchTerm("");
      runSearch(1);
    };

    ctrl.loadMore = function () {
      ctrl.loadingMore = true;
      runSearch(ctrl.page + 1);
    };

    ctrl.closeSearch = function () {
      $rootScope.showSearch = false;
      $location.search("search", null);
      $("body").css("overflow-y", "scroll");
    };

    // $rootScope.$watch("cart", function (cart) {
    //   if (cart) ctrl.closeSearch();
    // });

    ctrl.watchKeys = function (e) {
      if (e.keyCode === 27) return ctrl.closeSearch();
    };

    ctrl.toggleSale = function () {
      ctrl.seasonalSale = !ctrl.seasonalSale;
      runSearch(1);
    };

    function debounce(func, delay) {
      let timer;
      return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
      };
    }

    function resultsMessage(resultCount) {
      return (resultCount > 0 ? resultCount : "No") + " Products Found";
    }

    function updateDisabled(resultsCount) {
      // !TODO: recheck setTimeout
      // setTimeout(function () {
      ctrl.disabled = resultsCount <= ctrl.products.length;
      // }, 200);
    }

    function runSearch(page, pages = 0) {
      const currentReq = ++ctrl.requestNumber;
      // const currentTerm = $rootScope.searchTerm;

      ctrl.page = page;
      var category = keywords.exec($rootScope.searchTerm);
      updateSearchTerm($rootScope.searchTerm);

      if (category)
        updateCategoryFilter(category[0].replace(/\s+/g, "-").toLowerCase());

      ctrl.searching = true;
      ctrl.disabled = true;

      if (page === 1) {
        ctrl.products = [];
      }
      setPageNumber(page);

      var query = {
        page: ctrl.page,
        search: $rootScope.searchTerm,
        count: productCount,
        getCount: true,
        sort: "popular",
      };

      if (ctrl.seasonalSale) {
        query.seasonal_sale = true;
      }

      if ($rootScope.appFilter && $rootScope.appFilter !== "Any Platform") {
        query.compatibility = $rootScope.appFilter;
      }

      if (
        $rootScope.categoryFilter &&
        $rootScope.categoryFilter !== "all" &&
        $rootScope.categoryFilter !== "freebies"
      ) {
        query.category = $rootScope.categoryFilter;
      }

      // if ($rootScope.categoryFilter === "freebies") {
      //   query.freebie = true;
      // }

      return ProductService.list(query)
        .then(function (response) {
          if (ctrl.requestNumber !== currentReq) {
            return; // Another request was started, we don't need this data
          }
          ctrl.products = ctrl.products.concat(response.products);
          ctrl.resultsMessage = resultsMessage(response.totalCount);
          updateDisabled(response.totalCount);

          ctrl.empty = !response.totalCount;

          if (page < pages) {
            runSearch(++page, pages);
          } else if (pages !== 0) {
            setTimeout(function () {
              document.querySelector(".search").scrollTop = getTop();
            }, 0);
          }
        })
        .catch(function (e) {
          console.error(e);
          updateDisabled(0);
        })
        .finally(function () {
          ctrl.searching = false;
          ctrl.loadingMore = false;
        });
    }

    function updateCategoryFilter(val) {
      val = val || sessionStorage.getItem("search:category-filter");

      $rootScope.categoryFilter = val || "any";
      sessionStorage.setItem(
        "search:category-filter",
        $rootScope.categoryFilter
      );
    }

    function updateAppFilter(val) {
      val = val || sessionStorage.getItem("search:app-filter");

      $rootScope.appFilter = val || "Any Platform";
      sessionStorage.setItem("search:app-filter", $rootScope.appFilter);
    }

    function restoreSearchTerm() {
      return sessionStorage.getItem("search:term") || "";
    }

    function updateSearchTerm(val) {
      sessionStorage.setItem("search:term", val);
    }

    function setPageNumber(page) {
      sessionStorage.setItem("search:page", page);
    }

    function getPageNumber() {
      var page = sessionStorage.getItem("search:page") || 1;
      sessionStorage.removeItem("search:page");
      return +page;
    }

    function getTop() {
      var top = sessionStorage.getItem("top:search") || 0;
      sessionStorage.removeItem("top:search");
      return parseInt(top, 10);
    }

    // function updateSearchInput(term) {
    //   if (term && $searchInput && $searchInput.val() !== term) {
    //     $searchInput.val(term);
    //   }
    // }
  },
});

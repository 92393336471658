angular.module("UI8.components").component("accountSettings", {
  template: `<div class="page page--additional">
      <div class="page__container container">
        <div class="page__hero">
          <h1 class="page__title">Account settings</h1>
          <div class="page__description">Manage your profile, security, payment and notification settings.</div>
        </div>
        <div class="layout layout--static layout--small">
          <div class="layout__sidebar">
            <div class="layout__nav">
              <a href="/account/settings?open=profile" class="layout__link" ng-class="{ active: $ctrl.openPane === 'profile' }" ng-click="$ctrl.activeSection('profile')">Profile</a>
              <a href="/account/settings?open=password" class="layout__link" ng-class="{ active: $ctrl.openPane === 'password' }" ng-click="$ctrl.activeSection('password')">Security</a>
              <a href="/account/settings?open=payment" class="layout__link" ng-class="{ active: $ctrl.openPane === 'payment' }" ng-click="$ctrl.activeSection('payment')">Payment methods</a>
              <a href="/account/settings?open=notifications" class="layout__link" ng-class="{ active: $ctrl.openPane === 'notifications' }" ng-click="$ctrl.activeSection('notifications')">Notifications</a>
              <a href="/account/settings?open=deleting" class="layout__link" ng-class="{ active: $ctrl.openPane === 'deleting' }" ng-click="$ctrl.activeSection('deleting')">Delete account</a>
            </div>
          </div>
          <div class="layout__inner">
            <div class="account">
              <div class="account__section" ng-class="{ visible: $ctrl.openPane === 'profile' }">
                <form method="POST" action="/account/settings/update" ng-submit="$ctrl.isSubmitting = true" novalidate>
                  <div class="account__title">Avatar</div>
                  <div class="account__content">Update your avatar by clicking the image below. 288x288 px size recommended in PNG or JPG format only.</div>
                  <div class="account__upload">
                    <input type="hidden" name="avatar_url" value="{{ $ctrl.upload.url || $ctrl.data.user.avatar_url || ''}}">
                    <div class="account__preview">
                      <svg width="56" height="56" ng-if="!$ctrl.upload.processing && (!$ctrl.upload.url || !$ctrl.data.user.avatar_url)">
                        <path d="M28 36.993c8.359 0 16.204 3.56 22.091 9.804a1.5 1.5 0 1 1-2.183 2.058C42.574 43.198 35.512 39.993 28 39.993s-14.574 3.205-19.909 8.862a1.5 1.5 0 1 1-2.183-2.058c5.888-6.245 13.733-9.804 22.091-9.804zM28 6.66c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 3a9 9 0 1 1 0 18 9 9 0 1 1 0-18z"/>
                      </svg>
                      <div class="account__avatar" ng-if="!$ctrl.upload.processing && ($ctrl.upload.url || $ctrl.data.user.avatar_url)">
                        <img ng-if="!$ctrl.upload.completed && !$ctrl.upload.url && $ctrl.data.user.avatar_url" ng-src="{{$ctrl.data.user.avatar_url}}" alt="Avatar"/>
                        <img ng-if="$ctrl.upload.completed && $ctrl.upload.url" ng-src="{{$ctrl.upload.url}}" alt="Avatar"/>
                      </div>
                      <div class="loader" ng-if="$ctrl.upload.processing"></div>
                      <div class="account__uploader" ng-model="$ctrl.upload" ui8-upload ui8-upload-max-size="10000" ui8-upload-complete="$ctrl.avatarComplete" accept=".png,.jpg"></div>
                      <button class="btn btn--stroke btn--icon-only" ng-if="$ctrl.data.user.avatar_url || $ctrl.upload.completed" ng-click="$ctrl.data.user.avatar_url = ''; $ctrl.upload = [];">
                        <svg><path d="M6.988 5.585l.094.083L12 10.585l4.918-4.917a1 1 0 0 1 1.497 1.32l-.083.094L13.415 12l4.917 4.918a1 1 0 0 1-1.32 1.497l-.094-.083L12 13.415l-4.918 4.917a1 1 0 0 1-1.497-1.32l.083-.094L10.585 12 5.668 7.082a1 1 0 0 1 1.219-1.567l.101.069z"/></svg>
                      </button>
                    </div>
                  </div>
                  <div class="account__title mb-34">Details</div>
                  <div class="field">
                    <div class="field__label">First name</div>
                    <input class="field__input tab-first-name" type="text" name="first_name" ng-model="$ctrl.data.user.first_name" required>
                  </div>
                  <div class="field">
                    <div class="field__label">Last name</div>
                    <input class="field__input" type="text" name="last_name" ng-model="$ctrl.data.user.last_name" required>
                  </div>
                  <div class="field">
                    <div class="field__label">Email</div>
                    <input class="field__input" type="email" name="email" ng-model="$ctrl.data.user.email" ng-pattern="/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$/i" required>
                  </div>
                  <div class="field">
                    <div class="field__label">Display name</div>
                    <input class="field__input" type="text" name="display_name" ng-model="$ctrl.data.user.display_name">
                  </div>
                  <div class="field mb-48">
                    <div class="field__label">Short bio</div>
                    <textarea class="field__textarea" ng-model="$ctrl.data.user.biography" name="biography" ng-maxlength="65"></textarea>
                    <input type="text" ui8-go-tab=".tab-first-name" />
                  </div>
                  <div class="account__head">
                    <div class="account__title">Hire me</div>
                    <div class="toggle" ng-class="{active: $ctrl.data.user.for_hire}" ng-click="$ctrl.data.user.for_hire = !$ctrl.data.user.for_hire">
                      <span ng-if="!$ctrl.data.user.for_hire">Disabled</span>
                      <span ng-if="$ctrl.data.user.for_hire">Enabled</span>
                    </div>
                    <input type="hidden" name="for_hire" value="{{$ctrl.data.user.for_hire}}">
                  </div>
                  <div class="account__content">Enabling this feature will allow other users to contact you with work inquiries.</div>
                  <div class="account__head" ng-if="$ctrl.data.user.affiliate_type.indexOf('reseller') === -1">
                    <div class="account__title">Privacy</div>
                    <div class="toggle" ng-class="{active: $ctrl.data.user.private_profile}" ng-click="$ctrl.data.user.private_profile = !$ctrl.data.user.private_profile">
                      <span ng-if="!$ctrl.data.user.private_profile">Disabled</span>
                      <span ng-if="$ctrl.data.user.private_profile">Enabled</span>
                    </div>
                    <input type="hidden" name="private_profile" value="{{$ctrl.data.user.private_profile}}">
                  </div>
                  <div class="account__content" ng-if="$ctrl.data.user.affiliate_type.indexOf('reseller') === -1">Enabling privacy will hide your profile, only you will be able to see it. Not recommended if you’re an author.</div>
                  <div class="account__foot">
                    <button class="btn btn--submit" ng-if="!$ctrl.isSubmitting" type="submit">Save changes</button>
                    <div class="loader" ng-if="$ctrl.isSubmitting"></div>
                  </div>
                </form>
              </div>
              <div class="account__section" ng-class="{ visible: $ctrl.openPane === 'password' }">
                <div class="account__title">Update your password</div>
                <div class="account__content mb-34">You can update your password below. If you forgot your current password please contact support for assistance.</div>
                <form name="resetPassword" method="POST" action="/account/password/reset" ng-submit="$ctrl.isSubmitting = true" novalidate>
                  <div class="field" ng-init="currentPasswordHidden = false">
                    <div class="field__label">Current password</div>
                    <input class="field__input tab-current-password" ng-class="{error: !byOrder && ($ctrl.currentPasswordError || (resetPassword.current.$invalid && resetPassword.$submitted))}" type="{{ currentPasswordHidden ? 'text' : 'password' }}" name="current" ng-model="$ctrl.password.current" placeholder="Current password" ng-required="!$ctrl.byOrder">
                    <button tabindex="-1" class="field__action" type="button" ng-click="currentPasswordHidden = !currentPasswordHidden">
                      <svg ng-if="currentPasswordHidden" width="24" height="24"><path d="M12 4c4.517 0 7.994 2.902 10.426 6.753h0l.119.191.078.138.063.132a1.88 1.88 0 0 1 .112.339 2.16 2.16 0 0 1 0 .893 1.88 1.88 0 0 1-.112.339h0l-.063.132-.078.138-.119.192C19.994 17.098 16.517 20 12 20s-7.994-2.902-10.426-6.753h0l-.161-.264-.067-.129-.031-.068a1.88 1.88 0 0 1-.112-.339 2.16 2.16 0 0 1 0-.894 1.88 1.88 0 0 1 .112-.339 2.12 2.12 0 0 1 .063-.132h0l.078-.138.303-.479C4.181 6.759 7.597 4 12 4zm0 2c-3.646 0-6.633 2.494-8.735 5.821h0l-.111.178.293.463C5.538 15.647 8.459 18 12 18c3.646 0 6.634-2.494 8.735-5.821h0l.109-.181-.291-.46C18.463 8.353 15.542 6 12 6zm0 2a4 4 0 1 1 0 8 4 4 0 1 1 0-8zm0 2a2 2 0 1 0 0 4 2 2 0 1 0 0-4z"/></svg>
                      <svg ng-if="!currentPasswordHidden" width="24" height="24"><path d="M3.613 2.21l.094.083 3.652 3.649c.05.041.096.087.138.139l10.414 10.413c.058.046.112.1.16.16l3.636 3.639a1 1 0 0 1-1.32 1.497l-.094-.083-3.154-3.153C15.548 19.486 13.831 20 12 20c-4.404 0-7.819-2.759-10.241-6.466l-.303-.478-.078-.138a2.12 2.12 0 0 1-.063-.132 1.88 1.88 0 0 1-.112-.339 2.16 2.16 0 0 1 0-.894c.067-.293.141-.436.373-.802.947-1.499 2.153-2.952 3.618-4.143L2.293 3.707a1 1 0 0 1 1.32-1.497zm-.102 9.234l-.355.552V12l.111.179C5.367 15.506 8.355 18 12 18c1.282 0 2.504-.32 3.667-.918l-1.635-1.636A3.99 3.99 0 0 1 12 16a4 4 0 0 1-4-4 3.99 3.99 0 0 1 .554-2.031L6.617 8.032c-1.227.964-2.27 2.163-3.106 3.412zM12 4c4.517 0 7.994 2.902 10.426 6.753l.119.191.078.138.063.132a1.88 1.88 0 0 1 .112.339 2.16 2.16 0 0 1 0 .895c-.067.293-.142.436-.373.802a18.39 18.39 0 0 1-1.441 1.973 1 1 0 1 1-1.533-1.284c.397-.475.751-.951 1.061-1.414l.335-.52-.001-.007-.109-.177C18.634 8.494 15.646 6 12 6a7.59 7.59 0 0 0-1.111.082 1 1 0 1 1-.292-1.979C11.059 4.035 11.527 4 12 4zm-2 8a2 2 0 0 0 2 2c.178 0 .352-.023.518-.068l-2.451-2.45A2.01 2.01 0 0 0 10 12z"/></svg>
                    </button>
                    <div class="field__error" ng-if="resetPassword.current.$invalid && resetPassword.$submitted">Required field</div>
                    <div class="field__error" ng-if="$ctrl.currentPasswordError">Incorrect password</div>
                  </div>
                  <div class="field" ng-init="newPasswordHidden = false">
                    <div class="field__label">New password</div>
                    <input class="field__input" ng-class="{error: resetPassword.new.$invalid && resetPassword.$submitted}" type="{{ newPasswordHidden ? 'text' : 'password' }}" name="new" ng-model="$ctrl.password.new" placeholder="New password" required>
                    <button tabindex="-1" class="field__action" type="button" ng-click="newPasswordHidden = !newPasswordHidden">
                      <svg ng-if="newPasswordHidden" width="24" height="24"><path d="M12 4c4.517 0 7.994 2.902 10.426 6.753h0l.119.191.078.138.063.132a1.88 1.88 0 0 1 .112.339 2.16 2.16 0 0 1 0 .893 1.88 1.88 0 0 1-.112.339h0l-.063.132-.078.138-.119.192C19.994 17.098 16.517 20 12 20s-7.994-2.902-10.426-6.753h0l-.161-.264-.067-.129-.031-.068a1.88 1.88 0 0 1-.112-.339 2.16 2.16 0 0 1 0-.894 1.88 1.88 0 0 1 .112-.339 2.12 2.12 0 0 1 .063-.132h0l.078-.138.303-.479C4.181 6.759 7.597 4 12 4zm0 2c-3.646 0-6.633 2.494-8.735 5.821h0l-.111.178.293.463C5.538 15.647 8.459 18 12 18c3.646 0 6.634-2.494 8.735-5.821h0l.109-.181-.291-.46C18.463 8.353 15.542 6 12 6zm0 2a4 4 0 1 1 0 8 4 4 0 1 1 0-8zm0 2a2 2 0 1 0 0 4 2 2 0 1 0 0-4z"/></svg>
                      <svg ng-if="!newPasswordHidden" width="24" height="24"><path d="M3.613 2.21l.094.083 3.652 3.649c.05.041.096.087.138.139l10.414 10.413c.058.046.112.1.16.16l3.636 3.639a1 1 0 0 1-1.32 1.497l-.094-.083-3.154-3.153C15.548 19.486 13.831 20 12 20c-4.404 0-7.819-2.759-10.241-6.466l-.303-.478-.078-.138a2.12 2.12 0 0 1-.063-.132 1.88 1.88 0 0 1-.112-.339 2.16 2.16 0 0 1 0-.894c.067-.293.141-.436.373-.802.947-1.499 2.153-2.952 3.618-4.143L2.293 3.707a1 1 0 0 1 1.32-1.497zm-.102 9.234l-.355.552V12l.111.179C5.367 15.506 8.355 18 12 18c1.282 0 2.504-.32 3.667-.918l-1.635-1.636A3.99 3.99 0 0 1 12 16a4 4 0 0 1-4-4 3.99 3.99 0 0 1 .554-2.031L6.617 8.032c-1.227.964-2.27 2.163-3.106 3.412zM12 4c4.517 0 7.994 2.902 10.426 6.753l.119.191.078.138.063.132a1.88 1.88 0 0 1 .112.339 2.16 2.16 0 0 1 0 .895c-.067.293-.142.436-.373.802a18.39 18.39 0 0 1-1.441 1.973 1 1 0 1 1-1.533-1.284c.397-.475.751-.951 1.061-1.414l.335-.52-.001-.007-.109-.177C18.634 8.494 15.646 6 12 6a7.59 7.59 0 0 0-1.111.082 1 1 0 1 1-.292-1.979C11.059 4.035 11.527 4 12 4zm-2 8a2 2 0 0 0 2 2c.178 0 .352-.023.518-.068l-2.451-2.45A2.01 2.01 0 0 0 10 12z"/></svg>
                    </button>
                    <div class="field__error" ng-if="resetPassword.new.$invalid && resetPassword.$submitted">Required field</div>
                  </div>
                  <div class="field" ng-init="confirmPasswordHidden = false">
                    <div class="field__label">Confirm new password</div>
                    <input class="field__input" ng-class="{error: resetPassword.new_confirm.$invalid && resetPassword.$submitted || resetPassword.new_confirm.$error.ui8Compare && resetPassword.new_confirm.$viewValue}" type="{{ confirmPasswordHidden ? 'text' : 'password' }}" name="new_confirm" ng-model="$ctrl.password.new_confirm" ui8-compare="$ctrl.password.new" placeholder="Confirm new password" required>
                    <input type="text" ui8-go-tab=".tab-current-password" />
                    <button tabindex="-1" class="field__action" type="button" ng-click="confirmPasswordHidden = !confirmPasswordHidden">
                      <svg ng-if="confirmPasswordHidden" width="24" height="24"><path d="M12 4c4.517 0 7.994 2.902 10.426 6.753h0l.119.191.078.138.063.132a1.88 1.88 0 0 1 .112.339 2.16 2.16 0 0 1 0 .893 1.88 1.88 0 0 1-.112.339h0l-.063.132-.078.138-.119.192C19.994 17.098 16.517 20 12 20s-7.994-2.902-10.426-6.753h0l-.161-.264-.067-.129-.031-.068a1.88 1.88 0 0 1-.112-.339 2.16 2.16 0 0 1 0-.894 1.88 1.88 0 0 1 .112-.339 2.12 2.12 0 0 1 .063-.132h0l.078-.138.303-.479C4.181 6.759 7.597 4 12 4zm0 2c-3.646 0-6.633 2.494-8.735 5.821h0l-.111.178.293.463C5.538 15.647 8.459 18 12 18c3.646 0 6.634-2.494 8.735-5.821h0l.109-.181-.291-.46C18.463 8.353 15.542 6 12 6zm0 2a4 4 0 1 1 0 8 4 4 0 1 1 0-8zm0 2a2 2 0 1 0 0 4 2 2 0 1 0 0-4z"/></svg>
                      <svg ng-if="!confirmPasswordHidden" width="24" height="24"><path d="M3.613 2.21l.094.083 3.652 3.649c.05.041.096.087.138.139l10.414 10.413c.058.046.112.1.16.16l3.636 3.639a1 1 0 0 1-1.32 1.497l-.094-.083-3.154-3.153C15.548 19.486 13.831 20 12 20c-4.404 0-7.819-2.759-10.241-6.466l-.303-.478-.078-.138a2.12 2.12 0 0 1-.063-.132 1.88 1.88 0 0 1-.112-.339 2.16 2.16 0 0 1 0-.894c.067-.293.141-.436.373-.802.947-1.499 2.153-2.952 3.618-4.143L2.293 3.707a1 1 0 0 1 1.32-1.497zm-.102 9.234l-.355.552V12l.111.179C5.367 15.506 8.355 18 12 18c1.282 0 2.504-.32 3.667-.918l-1.635-1.636A3.99 3.99 0 0 1 12 16a4 4 0 0 1-4-4 3.99 3.99 0 0 1 .554-2.031L6.617 8.032c-1.227.964-2.27 2.163-3.106 3.412zM12 4c4.517 0 7.994 2.902 10.426 6.753l.119.191.078.138.063.132a1.88 1.88 0 0 1 .112.339 2.16 2.16 0 0 1 0 .895c-.067.293-.142.436-.373.802a18.39 18.39 0 0 1-1.441 1.973 1 1 0 1 1-1.533-1.284c.397-.475.751-.951 1.061-1.414l.335-.52-.001-.007-.109-.177C18.634 8.494 15.646 6 12 6a7.59 7.59 0 0 0-1.111.082 1 1 0 1 1-.292-1.979C11.059 4.035 11.527 4 12 4zm-2 8a2 2 0 0 0 2 2c.178 0 .352-.023.518-.068l-2.451-2.45A2.01 2.01 0 0 0 10 12z"/></svg>
                    </button>
                    <div class="field__error" ng-if="resetPassword.new_confirm.$invalid && resetPassword.$submitted">Required field</div>
                    <div class="field__error" ng-if="resetPassword.new_confirm.$error.ui8Compare && resetPassword.new_confirm.$viewValue">Passwords do not match</div>
                  </div>
                  <div class="account__foot">
                    <button class="btn btn--submit" ng-if="!$ctrl.isSubmitting" type="submit">Save changes</button>
                    <div class="loader" ng-if="$ctrl.isSubmitting"></div>
                  </div>
                </form>
              </div>
              <div class="account__section" ng-class="{ visible: $ctrl.openPane === 'payment' }">
                <div class="account__step" ng-if="!$ctrl.newCardActive && $ctrl.data.cards">
                  <div class="account__title">Saved cards</div>
                  <div class="account__content mb-34">Manage your stored cards for a more seamless shopping experience. Your card details are secured and encrypted by Stripe.</div>
                  <div class="field" ng-class="{ active: $ctrl.data.defaultCard.id === card.id }" ng-repeat="card in $ctrl.data.cards track by $index">
                    <div class="field__label">Card {{ $index + 1 }}</div>
                    <form method="POST" action="/account/payment/remove">
                      <input type="hidden" name="customer" value="{{card.customer}}">
                      <input type="hidden" name="card" value="{{card.id}}">
                      <button class="field__link field__link--label" type="submit">Remove</button>
                    </form>
                    <div class="field__groups">
                      <div class="field__group field__group--first">
                        <div class="field__input">
                          <svg width="173" height="8" viewBox="0 0 173 8" fill="#929292">
                            <circle cx="4"></circle>
                            <circle cx="17"></circle>
                            <circle cx="30"></circle>
                            <circle cx="43"></circle>
                            <circle cx="67"></circle>
                            <circle cx="80"></circle>
                            <circle cx="93"></circle>
                            <circle cx="106"></circle>
                            <circle cx="130"></circle>
                            <circle cx="143"></circle>
                            <circle cx="156"></circle>
                            <circle cx="169"></circle>
                          </svg>
                          <span>{{ card.last4 }}</span>
                        </div>
                        <div class="field__system" ng-class="$ctrl.slugify(card.brand)"></div>
                      </div>
                      <div class="field__group field__group--expired">
                        <div class="field__input field__input--month">{{ card.exp_month }}</div>
                        <div class="field__input field__input--year">{{ card.exp_year.toString().slice(-2) }}</div>
                      </div>
                      <div class="field__group field__group--cvv">
                        <div class="field__input">
                          <a class="toggle" ng-class="{ active: $ctrl.data.defaultCard.id === card.id }" ng-href="/account/payment/default/{{card.id}}" ng-click="$ctrl.savingDefaultCard = true">Default</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="account__foot">
                    <button class="btn btn--submit" ng-click="$ctrl.toggleNewCard()">Add a new card</button>
                  </div>
                </div>
                <form class="account__step" ng-if="$ctrl.newCardActive || !$ctrl.data.cards" name="PaymentForm" no-action novalidate>
                  <div class="account__title">Add new card</div>
                  <div class="account__content mb-34">Add a new card for future purchases and enable a more seamless shopping experience. Your card details are secured and encrypted by Stripe.</div>
                  <div class="field">
                    <div class="field__label">Card information</div>
                    <input type="hidden" name="stripeToken" value="{{ $ctrl.stripeToken }}">
                    <div class="field__groups">
                      <div class="field__group field__group--first">
                        <input class="field__input tab-card-name" ng-class="{error: PaymentForm.name.$invalid && PaymentForm.$submitted}" type="text" name="name" ng-model="$ctrl.newCard.name" placeholder="Name on card" required>
                      </div>
                      <div class="field__group field__group--second">
                        <input class="field__input" ng-class="{error: PaymentForm.number.$invalid && PaymentForm.$submitted}" type="text" name="number" ng-model="$ctrl.newCard.number" cc-number cc-type="$ctrl.newCard.cardType" cc-eager-type cc-format placeholder="1234 1234 1234 1234" required>
                        <div class="field__system" ng-class="PaymentForm.number.$ccEagerType.toLowerCase().replace(' ','-')"></div>
                      </div>
                      <div class="field__group field__group--expired hover" ng-class="{focus: focus, error: (PaymentForm.exp_month.$invalid || PaymentForm.exp_year.$invalid) && PaymentForm.$submitted}">
                        <input class="field__input field__input--month" ng-keyup="$ctrl.advanceCursor($event)" ng-focus="focus=true" ng-blur="focus=false" type="text" name="exp_month" ng-model="$ctrl.newCard.exp_month" cc-exp-month placeholder="MM" required>
                        <input class="field__input field__input--year" ng-keyup="$ctrl.advanceCursor($event)" ng-focus="focus=true" ng-blur="focus=false" type="text" name="exp_year" ng-model="$ctrl.newCard.exp_year" cc-exp-year placeholder="YY" required>
                      </div>
                      <div class="field__group field__group--cvv">
                        <input class="field__input" ng-class="{error: PaymentForm.cvc.$invalid && PaymentForm.$submitted}" type="password" name="cvc" ng-model="$ctrl.newCard.cvc" cc-cvc placeholder="CVC" required>
                        <input type="text" ui8-go-tab=".tab-card-name" />
                      </div>
                    </div>
                  </div>
                  <div class="account__foot">
                    <button class="btn btn--stroke btn--submit" ng-if="!$ctrl.savingCard && $ctrl.data.cards" ng-click="$ctrl.toggleNewCard()">Cancel</button>
                    <button class="btn btn--submit" ng-if="!$ctrl.savingCard" ng-click="$ctrl.saveCard(PaymentForm)">Save card</button>
                    <div class="loader" ng-if="$ctrl.savingCard"></div>
                  </div>
                </form>
              </div>
              <div class="account__section" ng-class="{ visible: $ctrl.openPane === 'notifications' }">
                <div class="account__title">Email notifications</div>
                <div class="account__content">Trigger email notifications based on the following events:</div>
                <form class="account__notifications" method="POST" action="/account/emails" ng-submit="$ctrl.isSubmitting = true" name="notificationsForm" novalidate>
                  <div class="toggle toggle--first" ng-class="{active: $ctrl.notifications.on_mention}" ng-click="$ctrl.notifications.on_mention = !$ctrl.notifications.on_mention">
                    Mentioned in comments
                    <input type="hidden" name="on_mention" value="{{$ctrl.notifications.on_mention}}">
                  </div>
                  <div class="toggle" ng-class="{active: $ctrl.notifications.on_comment}" ng-click="$ctrl.notifications.on_comment = !$ctrl.notifications.on_comment">
                    New comment in my product
                    <input type="hidden" name="on_comment" value="{{$ctrl.notifications.on_comment}}">
                  </div>
                  <div class="toggle" ng-class="{active: $ctrl.notifications.on_follow}" ng-click="$ctrl.notifications.on_follow = !$ctrl.notifications.on_follow">
                    New followers
                    <input type="hidden" name="on_follow" value="{{$ctrl.notifications.on_follow}}">
                  </div>
                  <div class="toggle" ng-class="{active: $ctrl.notifications.on_payout}" ng-click="$ctrl.notifications.on_payout = !$ctrl.notifications.on_payout">
                    Payout processed
                    <input type="hidden" name="on_payout" value="{{$ctrl.notifications.on_payout}}">
                  </div>
                  <div class="toggle" ng-class="{active: $ctrl.notifications.on_sale}" ng-click="$ctrl.notifications.on_sale = !$ctrl.notifications.on_sale">
                    Product sales
                    <input type="hidden" name="on_sale" value="{{$ctrl.notifications.on_sale}}">
                  </div>
                  <div class="toggle" ng-class="{active: $ctrl.notifications.on_new_product}" ng-click="$ctrl.notifications.on_new_product = !$ctrl.notifications.on_new_product">
                    New releases
                    <input type="hidden" name="on_new_product" value="{{$ctrl.notifications.on_new_product}}">
                  </div>
                  <div class="toggle toggle--last" ng-class="{active: $ctrl.notifications.site_notifications}" ng-click="$ctrl.notifications.site_notifications = !$ctrl.notifications.site_notifications">
                    Platform news and updates
                    <input type="hidden" name="site_notifications" value="{{$ctrl.notifications.site_notifications}}">
                  </div>
                  <div class="account__foot">
                    <button class="btn btn--submit" ng-if="!$ctrl.isSubmitting" type="submit">Save preferences</button>
                    <div class="loader" ng-if="$ctrl.isSubmitting"></div>
                  </div>
                </form>
              </div>
              <div class="account__section" ng-class="{ visible: $ctrl.openPane === 'deleting' }">
                <div class="account__title">Delete account</div>
                <div ng-if="$ctrl.data.user.affiliate" class="account__content">
                  <p>Permanently deleting your account and all data associated with it is a manual process performed on our end.</p>
                  <p>Please contact support with the email address associated with the account you wish to delete for assistance.</p>
                </div>
                <div ng-if="!$ctrl.data.user.affiliate" class="account__content">
                  <p>Permanently deleting your account and all associated data is irreversible. Proceed with caution.</p>
                  <p>You can delete your account by clicking the Delete Account button below.</p>
                </div>
                <div class="account__foot">
                  <button ng-if="$ctrl.data.user.affiliate" class="btn btn--submit" onclick="Intercom('show')">Contact support</button>
                  <button ng-if="!$ctrl.data.user.affiliate && !$ctrl.deletingAccount" class="btn btn--submit btn--red" ng-click="$ctrl.deleteAccount()">Delete Account</button>
                  <div class="loader" ng-if="$ctrl.deletingAccount"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`,

  controller: function ($http, FlashService, PushService) {
    var ctrl = this;

    var panes = ["profile", "password", "payment", "notifications", "deleting"];

    ctrl.$onInit = function () {
      window.scrollTo(0, 0);
      ctrl.openPane = ctrl.data.openView || "profile";
      ctrl.byOrder = ctrl.data.user.created_by === "order";
      ctrl.removeCardObject = {};
      ctrl.notifications = {};
      ctrl.data.user.notifications.split(" ").forEach(function (n) {
        ctrl.notifications[n] = 1;
      });
      ctrl.newCard = {};

      var searchParams = new URLSearchParams(window.location.search);
      var openParam = searchParams.get("open");
      if (openParam && panes.includes(openParam)) {
        ctrl.openPane = openParam;
      }

      angular
        .element(".add-card-form")
        .find('[type="submit"]')
        .bind("click", function (e) {
          e.preventDefault();
          ctrl.saveCard();
        });

      initPushNotificationHandler();
    };

    ctrl.$onDestroy = function () {};

    ctrl.activeSection = function (section) {
      ctrl.openPane = section;
    };

    ctrl.avatarComplete = function () {
      if (ctrl.upload && ctrl.upload.url) {
        ctrl.data.user.avatar_url = ctrl.upload.url;

        ctrl.upload.processing = false;
      }
    };

    ctrl.slugify = function (input) {
      return (input || "")
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w\-]+/g, "") // Remove all non-word chars
        .replace(/\-\-+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    };

    ctrl.toggleNewCard = function () {
      ctrl.newCardActive = !ctrl.newCardActive;
    };

    ctrl.advanceCursor = function (e) {
      if (e.target.value.length < 2) return;
      if (["TAB", "SHIFT"].includes(e.key.toUpperCase())) return;

      if (e.target.name === "exp_month") {
        $("input[name='exp_year']").focus();
      } else if (e.target.name === "exp_year") {
        $("input[name='cvc").focus();
      }
    };

    ctrl.saveCard = function (PaymentForm) {
      if (PaymentForm.$invalid) return;

      ctrl.savingCard = true;

      // Fetch Stripe token and post form
      Stripe.card.createToken(ctrl.newCard, function (status, res) {
        // If Stripe Error
        if (status !== 200 || res.error) {
          if (res.error.param && ctrl.PaymentForm[res.error.param]) {
            ctrl.PaymentForm[res.error.param].$setValidity("required", false);
          } else {
            FlashService.set(
              "error",
              "There was an error processing your token"
            );
          }
          ctrl.savingCard = false;
        }

        // Update Stripe info in hidden form fields
        ctrl.newCard.stripeToken = res.id;

        // Get recaptcha token
        var rc = grecaptcha || window.grecaptcha;
        var rcKey = UI8.recaptchaSiteKey;
        if (!rc || !rcKey)
          FlashService.set(
            "error",
            "Unable to process transaction. Please try again later."
          );

        rc.execute(rcKey, { action: "addCard" }).then(function (token) {
          ctrl.newCard.rcToken = token;
          // Submit the form
          $http
            .post("/api/account/payment/add", ctrl.newCard)
            .then(function (response) {
              if (response.data.status === "success") {
                location.reload();
              } else if (response.data.error) {
                FlashService.set("error", response.data.error);
              } else {
                FlashService.set("error", "There was an error adding the card");
              }
              ctrl.savingCard = false;
            })
            .catch(function () {
              FlashService.set("error", "There was an error adding the card");
            });
        });
      });
    };

    ctrl.deleteAccount = function () {
      if (ctrl.data.user.affiliate) {
        return;
      }

      ctrl.deletingAccount = true;

      $http
        .post("/api/account/delete", ctrl.data.user)
        .then(function (response) {
          if (response.data.status === "success") {
            location.href = "/";
            FlashService.set("success", "Account successfully deleted");
          } else {
            FlashService.set(
              "error",
              "There was an error deleting your account. Contact support."
            );
          }
        })
        .catch(function () {
          FlashService.set(
            "error",
            "There was an error deleting your account. Contact support."
          );
        })
        .finally(function () {
          ctrl.deletingAccount = false;
        });
    };

    // Set up for handling push notification subscription
    function initPushNotificationHandler() {
      // Set up for handling push notification subscription
      if (!("serviceWorker" in navigator)) {
        // Service Worker isn't supported on this browser, disable or hide UI.
        return;
      }

      if (!("PushManager" in window)) {
        // Push isn't supported on this browser, disable or hide UI.
        return;
      }

      // Push is supported.
      PushService.setUpPush()
        .then(function (subscription) {
          ctrl.pushSubscription = subscription;

          return new Promise(function (resolve) {
            resolve(subscription);
          });
        })
        .then(function (subscription) {
          if (
            !subscription /* && $rootScope.flashMessage === 'Thank you for your order!'*/
          ) {
            return PushService.subscribe();
          } else {
            return new Promise(function (resolve) {
              resolve(subscription);
            });
          }
        })
        .then(function (subscription) {
          ctrl.pushSubscription = subscription;
        })
        .catch(function () {
          ctrl.pushSubscription = null;
        });
    }
  },

  bindings: {
    data: "<",
  },
});

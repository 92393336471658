angular.module("UI8.controllers").controller("CartController", [
  "$scope",
  "$rootScope",
  "AccountService",
  "CartService",
  "FlashService",
  "PaymentService",
  "UserService",
  "PromoService",
  function (
    $scope,
    $rootScope,
    AccountService,
    CartService,
    FlashService,
    PaymentService,
    UserService,
    PromoService
  ) {
    var $body;
    var $groupCart;
    var $groupDropdown;
    var $groupClose;
    var $groupNext;
    var $groupLayout;
    var $groupBack;

    $scope.passInCart = false;

    function updateScope(cart) {
      if (cart && cart.items) cart.items.reverse();
      $scope.cart = cart ? cart : { items: [] };
      $scope.itemCount = cart && cart.items_sum ? cart.items_sum : 0;
      // $scope.totalCart =
      //   cart && (cart.discounted || cart.total)
      //     ? cart.discounted || cart.total
      //     : 0;
      $scope.passInCart =
        cart && cart.items
          ? !!cart.items.filter(function (item) {
              return !!item.product.is_subscription;
            }).length
          : false;
      $scope.addSeats =
        cart && cart.items
          ? !!cart.items.filter(function (item) {
              return !!item.add_seats;
            }).length
          : false;

      $scope.promoIsAAP40 =
        $scope.cart.promotion &&
        ($scope.cart.promotion.code === "aap40" ||
          $scope.cart.promotion.code === "passrenew40" ||
          $scope.cart.promotion.code === "passupgrade40");

      $scope.showPaypalButton =
        !$scope.promoIsAAP40 ||
        ($scope.promoIsAAP40 &&
          ($scope.cart.items[0].product._id === "5cae4deda4f5d2176143d1c3" ||
            $scope.cart.items[0].product._id === "55314fb422912718375a0196" ||
            $scope.cart.items[0].product._id === "5963b6f703569f0016e316a3"));
    }

    function setUserParams(user) {
      if (!user) return;
      $scope.payment_details.email = $scope.currentUserEmail =
        user.email || undefined;
      $scope.payment_details.name = user.first_name
        ? user.first_name + " " + user.last_name
        : undefined;

      AccountService.listCards(function (err, cardData) {
        if (err) console.log(err);

        var defaultCard = cardData.defaultCard || undefined;
        $scope.default_card = defaultCard;
      });
    }

    function bodyClickHandler() {
      if ($groupCart.hasClass("active")) {
        $body.removeClass("cart");
        $groupCart.removeClass("active");
        window.scrollLock.enablePageScroll();
      }
    }

    $scope.payment_details = {};
    $scope.userExists = false;
    $scope.enteringPromotion = false;
    $scope.cardPlaceholder = "Card Number";
    $scope.cvcPlaceholder = "CVC";
    $scope.newPromoCode = "";
    $scope.useNewCard = false;

    $scope.init = function () {
      $scope.processor = "stripe";
      $scope.saveCard = true;
      $scope.passwordHidden = true;

      $body = $("body");
      $groupCart = $(".header__cell--cart");

      if ($rootScope.user) setUserParams($rootScope.user);

      CartService.getCart(function (err, cart) {
        updateScope(cart);

        setTimeout(function () {
          $("body").addClass("loaded");

          if ($scope.itemCount) {
            $groupNext = $groupCart.find(".payup__sidebar .payup__btn");
            $groupLayout = $groupCart.find(".payup__layout");
            $groupBack = $groupCart.find(".payup__back");

            $groupNext.on("click", function () {
              $groupLayout.addClass("step-checkout");
            });

            $groupBack.on("click", function () {
              $groupLayout.removeClass("step-checkout");
            });
          }
        }, 10);
      });

      if (
        window &&
        window.location.search &&
        /showCart/.test(window.location.search)
      ) {
        $groupCart.addClass("active");
        $body.addClass("cart");
      }

      $groupCart.find(".header__head").on("click", function (e) {
        e.stopPropagation();

        $body
          .find(".header__cell:not(.header__cell--cart)")
          .removeClass("active-touch");

        if ($scope.itemCount) $body.toggleClass("cart");

        if ($groupCart.hasClass("active")) {
          $groupCart.removeClass("active");
          window.scrollLock.enablePageScroll();
        } else {
          $groupCart.addClass("active");
          window.scrollLock.disablePageScroll();
        }
      });

      $groupCart.on("click", function (e) {
        e.stopPropagation();
      });

      $groupClose = $groupCart.find(".header__logo, .header__close");
      $groupClose.on("click", function () {
        $body.removeClass("cart");
        $groupCart.removeClass("active");
        window.scrollLock.enablePageScroll();
      });

      $groupDropdown = $groupCart.find(".header__dropdown");
      $groupDropdown.on("scroll", function () {
        if ($groupDropdown.scrollTop() > 0) {
          $groupDropdown.addClass("active");
        } else {
          $groupDropdown.removeClass("active");
        }
      });

      $groupCart.on("mouseleave", function () {
        if (!$scope.itemCount) {
          $groupCart.removeClass("active");
          $body.removeClass("cart");
          window.scrollLock.enablePageScroll();
        }
      });

      $body.on("click", bodyClickHandler);
    };

    $scope.$on("$destroy", function () {
      $groupCart.off();
      $body.off("click", bodyClickHandler);
    });

    $scope.togglePasswordVisibility = function ($event) {
      $event.stopPropagation();
      var input = $($event.currentTarget).siblings("input");
      if (input.attr("type") === "password") {
        input.attr("type", "text");
        $scope.passwordHidden = false;
      } else {
        input.attr("type", "password");
        $scope.passwordHidden = true;
      }
    };

    $scope.checkUserEmail = function () {
      if ($scope.currentUserEmail) return;

      UserService.userExists($scope.payment_details.email, function (
        err,
        exists
      ) {
        $scope.userExists = exists;
      });
    };

    $scope.removeItem = function (itemId) {
      if ($scope.cart.items.length <= 1) {
        $scope.itemCount = 0;
        $scope.cart.items = [];
        $body.removeClass("cart");
        window.scrollLock.enablePageScroll();
      } else {
        $scope.cart.items = $scope.cart.items.filter(function (item) {
          return String(item._id) !== itemId;
        });
      }

      CartService.removeFromCart(itemId, function (error, cart) {
        updateScope(cart);
      });
    };

    $scope.updateProcessor = function (processor) {
      $scope.processor = processor;
    };

    $scope.creditCardType = function () {
      return (
        ($scope.PaymentForm.number &&
          $scope.PaymentForm.number.$ccEagerType &&
          $scope.PaymentForm.number.$ccEagerType
            .toLowerCase()
            .replace(" ", "-")) ||
        ($scope.default_card &&
          $scope.default_card.brand.toLowerCase().replace(" ", "-"))
      );
    };

    $scope.toggleCardSave = function () {
      if ($scope.default_card) return;
      $scope.saveCard = !$scope.saveCard;
    };

    $scope.toggleUseNewCard = function () {
      $scope.useNewCard = !$scope.useNewCard;
    };

    function processPayment() {
      $scope.processing = true;

      // Get recaptcha token
      var rc = grecaptcha || window.grecaptcha;
      var rcKey = UI8.recaptchaSiteKey;
      if (!rc || !rcKey) {
        if (window.bugsnagClient)
          window.bugsnagClient.notify("Checkout error: no recaptcha found");

        FlashService.set(
          "error",
          "Unable to process login. Please try again later."
        );
      }

      rc.execute(rcKey, { action: "checkout" })
        .then(function (token) {
          sendCheckoutToServer(token);
        })
        .catch(function (error) {
          console.log(error);
          if (window.bugsnagClient) window.bugsnagClient.notify(error);
        });
    }

    function trackPurchase() {
      try {
        //
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: $scope.processor + "_" + UI8.idempotencyKey,
            value: $scope.cart.total / 100,
            currency: "USD",
            items: $scope.cart.items.map((item) => ({
              item_name: item.product.name,
              price: item.subtotal / 100,
            })),
          },
        });

        // google tracking
        window.gtag("event", "conversion", {
          send_to: "AW-620864518/SoEKCMfMla0DEIbIhqgC",
          value: $scope.cart.total / 100,
          currency: "USD",
          transaction_id: $scope.processor + "_" + UI8.idempotencyKey,
        });

        // tapfiliate tracking
        window.tap("create", "37299-95e588", { integration: "javascript" });
        window.tap(
          "conversion",
          $scope.processor + "_" + UI8.idempotencyKey,
          $scope.cart.total / 100
        );
      } catch (e) {
        console.log(`Unable to track purchase: ${e.message}`);
        console.log(e);
      }
    }

    function removePromo(location) {
      PromoService.disablePromo().then(function () {
        UI8.promo = null;
        UI8.isRenewal = false;
        var event = new Event("promo-updated");
        document.body.dispatchEvent(event);
        window.location = location;
      });
    }

    function sendCheckoutToServer(token) {
      $scope.payment_details.rcToken = token;
      if (!UI8.idempotencyKey && window.uuidv4)
        UI8.idempotencyKey = window.uuidv4();
      $scope.payment_details.idempotencyKey = UI8.idempotencyKey;
      trackPurchase();

      if ($scope.processor === "stripe") {
        if ($scope.default_card && !$scope.payment_details.card_number) {
          $scope.payment_details.stripeType = "existing";
          $scope.payment_details.stripeSource = $scope.default_card.id;
        } else {
          $scope.payment_details.stripeType = "new";
          $scope.payment_details.saveCard = $scope.saveCard.toString();
        }

        PaymentService.stripe($scope.payment_details, function (err, res) {
          if (err || res.error) {
            if ($scope.payment_details.stripeToken)
              delete $scope.payment_details.stripeToken;
            $scope.processing = false;
            console.log(err || res.error);
            if (window.bugsnagClient)
              window.bugsnagClient.notify(err || res.error);

            UI8.idempotencyKey = window.uuidv4();

            return FlashService.set("error", err || res.error);
          }
          // success
          return removePromo("/account/purchases");
        });
      } else if ($scope.processor === "paypal") {
        PaymentService.paypal($scope.payment_details, function (err, res) {
          if (err || res.error)
            return FlashService.set("error", err || res.error);
          if (res.link) {
            // success
            return removePromo(res.link);
          }
          $scope.processing = false;
          FlashService.set("error", "Unable to submit payment");
        });
      } else {
        $scope.processing = false;
        FlashService.set("error", "Unable to submit payment");
      }
    }

    $scope.submitPayment = function () {
      $scope.submitted = true;
      if ($scope.userExists || $scope.PaymentForm.$invalid) return;
      return processPayment();
    };

    $scope.closeCart = function (e) {
      $groupCart.removeClass("active");
      if ($scope.itemCount) $body.removeClass("cart");
      if (e) e.stopPropagation();
    };

    $scope.getPlaceholders = function () {
      if ($scope.cart && $scope.cart.items) {
        if ($scope.cart.items.length > 1) return [];
        return new Array(3 - $scope.cart.items.length);
      }
      return [];
    };

    $scope.advanceCursor = function (e) {
      if (e.target.value.length < 2) return;
      if (["TAB", "SHIFT"].includes(e.key.toUpperCase())) return;

      if (e.target.name === "exp_month") {
        $("input[name='exp_year']").focus();
      } else if (e.target.name === "exp_year") {
        $("input[name='cvc").focus();
      }
    };

    $scope.subscriptionAccess = function (sub_term, lifetime) {
      if (lifetime) {
        return "Lifetime access";
      } else if (sub_term) {
        return sub_term + " month access";
      } else {
        return "";
      }
    };

    // Watch for new items being added to the cart
    $rootScope.$watch("cart", function (cart) {
      if (cart) {
        updateScope(cart);
        $groupCart.addClass("active");
        if (cart.items && cart.items.length) {
          $body.addClass("cart");
        } else {
          $body.removeClass("cart");
        }
      }
    });

    $rootScope.$watch("user", function (user) {
      setUserParams(user);
    });

    // Modify keydown handler on document when cart 'active' state changes
    var targetNode = document.getElementsByClassName("header__cell--cart")[0];
    var searchKeydown;
    var active;

    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.attributeName !== "class") return;
        var classes = [];

        if (Array.from) {
          classes = Array.from(targetNode.classList);
        } else {
          classes = [].slice.apply(targetNode.classList);
        }
        if (classes.indexOf("active") > -1 && !active) {
          active = true;
          $(targetNode).find('input[name="email"]').focus();
        } else if (searchKeydown) {
          active = false;
        }
      });
    });

    observer.observe(targetNode, { attributes: true });
  },
]);

(function () {
  // Define UI8 Application
  angular
    .module("UI8", [
      "ngResource",
      "ngCookies",
      "express-csrf",
      "UI8.filters",
      "UI8.services",
      "UI8.resources",
      "UI8.directives",
      "UI8.controllers",
      "UI8.components",
      "credit-cards",
      "ngFileUpload",
      "relativeDate",
    ])
    .config([
      "$provide",
      function ($provide) {
        $provide.decorator("$browser", [
          "$delegate",
          function ($delegate) {
            $delegate.onUrlChange = function () {};
            $delegate.url = function () {
              return "";
            };
            return $delegate;
          },
        ]);
      },
    ])
    .config(function ($httpProvider) {
      $httpProvider.defaults.headers.common["X-Requested-With"] =
        "XMLHttpRequest";
      $httpProvider.defaults.withCredentials = true;
    })
    .config([
      "$compileProvider",
      function ($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
      },
    ])
    .run(function ($rootScope, $location, $timeout, SessionService) {
      if ("scrollRestoration" in history) {
        history.scrollRestoration = "manual";
      } else {
        window.addEventListener("unload", function () {
          //set scroll position to the top of the page.
          window.scrollTo(0, 0);
        });
      }

      function openSearch(letter) {
        $rootScope.showSearch = true;
        if (letter != null) {
          $rootScope.searchTerm = letter;
          sessionStorage.setItem("search:term", letter);
        }
        $timeout(function () {
          $rootScope.$digest();
        });
        $location.search("search", 1);
        $("body").css("overflow-y", "hidden");
      }

      window.openSearch = openSearch;

      document.addEventListener("keydown", function (e) {
        var invalids = ["input", "select", "textarea"];

        if (invalids.indexOf(e.target.nodeName.toLowerCase()) !== -1) return;

        if ($rootScope.showSearch) {
          $("#search-input").focus();
        }

        // Ignore keypresses when Cmd, Ctrl, or Alt are pressed
        if (e.altKey || e.ctrlKey || e.metaKey) return;

        // Only react to numbers and letters
        if (e.keyCode < 48 || e.keyCode > 90) return;

        var letter = String.fromCharCode(e.keyCode);
        if (!e.shiftKey) letter = letter.toLowerCase();

        if (!$rootScope.showSearch) {
          return openSearch(letter);
        }
      });

      if (!$rootScope.user) {
        SessionService.setCurrentUser();
      }

      window.addEventListener("popstate", function updateWindowLocation() {
        window.removeEventListener("popstate", updateWindowLocation);
        window.location = window.location.href;
      });

      if ($location.search().support) Intercom("show");
    });

  // Application Namespaces
  angular.module("UI8.filters", []);
  angular.module("UI8.services", []);
  angular.module("UI8.resources", []);
  angular.module("UI8.directives", []);
  angular.module("UI8.controllers", []);
  angular.module("UI8.components", []);
})();

angular.module("UI8.components").component("productCard", {
  template:
    '<div class="product ng-cloak" on-load="$ctrl.attachClickHandler()">' +
    '  <div class="product__preview">' +
    '    <div ng-if="!$ctrl.product.card_video" class="product__media" ui8-resize-to="{{$ctrl.resizeTo}}" ui8-image="{{$ctrl.cardImage}}" ui8-image-scaled="{{$ctrl.product.has_scaled_images}}" style="background-color: {{$ctrl.product.card_primary}}"></div>' +
    '    <div ng-if="$ctrl.product.card_video" class="product__media">' +
    '      <video muted playsinline ui8-product-video="{{$ctrl.product.card_video}}" ui8-video-poster="{{$ctrl.product.card_image}}" on-load="$ctrl.playVideo()"></video>' +
    "    </div>" +
    '    <div class="product__over">' +
    '      <div class="product__like">' +
    '        <div class="product__counter ng-cloak">{{$ctrl.getCountModifier($ctrl.product._id) + $ctrl.product.likes}}</div>' +
    '        <button class="product__btn" ng-class="{active: $ctrl.isLiked($ctrl.product._id)}" ng-if="$ctrl.user" ng-click="$ctrl.likeProduct($ctrl.product._id)">' +
    '          <svg><path fill-rule="evenodd" clip-rule="evenodd" d="M11.9932 5.13581C9.9938 2.7984 6.65975 2.16964 4.15469 4.31001C1.64964 6.45038 1.29697 10.029 3.2642 12.5604C4.89982 14.6651 9.84977 19.1041 11.4721 20.5408C11.6536 20.7016 11.7444 20.7819 11.8502 20.8135C11.9426 20.8411 12.0437 20.8411 12.1361 20.8135C12.2419 20.7819 12.3327 20.7016 12.5142 20.5408C14.1365 19.1041 19.0865 14.6651 20.7221 12.5604C22.6893 10.029 22.3797 6.42787 19.8316 4.31001C17.2835 2.19216 13.9925 2.7984 11.9932 5.13581Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>' +
    "        </button>" +
    '        <a class="product__btn" ng-if="!$ctrl.user" href="/account/signup">' +
    '          <svg><path fill-rule="evenodd" clip-rule="evenodd" d="M11.9932 5.13581C9.9938 2.7984 6.65975 2.16964 4.15469 4.31001C1.64964 6.45038 1.29697 10.029 3.2642 12.5604C4.89982 14.6651 9.84977 19.1041 11.4721 20.5408C11.6536 20.7016 11.7444 20.7819 11.8502 20.8135C11.9426 20.8411 12.0437 20.8411 12.1361 20.8135C12.2419 20.7819 12.3327 20.7016 12.5142 20.5408C14.1365 19.1041 19.0865 14.6651 20.7221 12.5604C22.6893 10.029 22.3797 6.42787 19.8316 4.31001C17.2835 2.19216 13.9925 2.7984 11.9932 5.13581Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>' +
    "        </a>" +
    "      </div>" +
    '      <a class="product__btn" ng-href="{{$ctrl.productLink}}">' +
    '        <svg><path d="M12 3c3.167 0 5.748 1.423 7.661 3.046s3.22 3.495 3.876 4.551h0l.027.043c.13.208.299.479.385.858a2.47 2.47 0 0 1 0 1.005c-.085.379-.255.649-.385.858h0l-.027.043c-.656 1.056-1.966 2.93-3.876 4.551S15.167 21 12 21s-5.748-1.423-7.661-3.046-3.22-3.495-3.876-4.551h0l-.027-.043c-.13-.208-.299-.479-.385-.858a2.47 2.47 0 0 1 0-1.005c.085-.379.254-.649.385-.858h0l.027-.043c.656-1.056 1.966-2.93 3.876-4.551S8.833 3 12 3zm0 4.5c-2.445 0-4.426 2.015-4.426 4.5S9.555 16.5 12 16.5s4.426-2.015 4.426-4.5S14.445 7.5 12 7.5zm0 2.25c1.222 0 2.213 1.007 2.213 2.25s-.991 2.25-2.213 2.25S9.787 13.243 9.787 12 10.778 9.75 12 9.75z"/></svg>' +
    "      </a>" +
    '      <button class="product__btn" ng-if="($ctrl.user && $ctrl.user.pass_holder && $ctrl.product.type !== \'bundle\' && $ctrl.product.files && $ctrl.product.files.length && $ctrl.product.price)" ng-click="$ctrl.toggleDownloadModal()">' +
    '        <svg><path fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 6a1 1 0 0 1 1 1v5.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L11 13.586V8a1 1 0 0 1 1-1z"/></svg>' +
    "      </button>" +
    '      <button class="product__btn" ng-if="($ctrl.user && $ctrl.product.type !== \'bundle\' && $ctrl.product.files && $ctrl.product.files.length && !$ctrl.product.price)" ng-click="$ctrl.toggleDownloadModal()">' +
    '        <svg><path fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 6a1 1 0 0 1 1 1v5.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L11 13.586V8a1 1 0 0 1 1-1z"/></svg>' +
    "      </button>" +
    '      <a class="product__btn" ng-if="(!$ctrl.user && $ctrl.product.type !== \'bundle\' && $ctrl.product.files && $ctrl.product.files.length && !$ctrl.product.price)" href="/account/signup">' +
    '        <svg><path fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 6a1 1 0 0 1 1 1v5.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L11 13.586V8a1 1 0 0 1 1-1z"/></svg>' +
    "      </a>" +
    '      <button class="product__btn" ng-if="((!$ctrl.user || $ctrl.user.role === \'admin\' || ($ctrl.user && !$ctrl.user.pass_holder)) && $ctrl.product.price && !$ctrl.product.subscription_product && !$ctrl.user.pass_holder)" data-partial="angular_card" data-id="{{$ctrl.product.base_subscription_id || $ctrl.product._id}}" ng-click="$ctrl.addToCart($event)">' +
    '        <svg><path fill-rule="evenodd" d="M8.377 2.001h7.245l1.869.04c.527.042 1.012.131 1.475.352a4 4 0 0 1 1.726 1.588c.259.443.388.919.473 1.44.082.501.133 1.116.195 1.859l.707 8.482.131 2.158c-.001.601-.063 1.158-.295 1.686a4 4 0 0 1-1.759 1.912c-.508.275-1.057.383-1.656.434-.578.049-1.293.049-2.161.049H7.673c-.869 0-1.583 0-2.161-.049-.599-.05-1.148-.159-1.656-.434a4 4 0 0 1-1.759-1.912c-.232-.529-.294-1.085-.295-1.686-.001-.581.059-1.292.131-2.158L2.64 7.28l.195-1.859c.085-.522.214-.998.473-1.44a4 4 0 0 1 1.726-1.588c.463-.221.948-.31 1.475-.352.506-.04 1.123-.04 1.869-.04zm.623 6a1 1 0 1 0-2 0 5 5 0 0 0 5 5 5 5 0 0 0 5-5 1 1 0 1 0-2 0 3 3 0 0 1-3 3 3 3 0 0 1-3-3z"/></svg>' +
    "      </button>" +
    '      <button class="product__btn" ng-if="$ctrl.product.subscription_product && !$ctrl.userHasLifetime" ui8-toggle-class="pass-pricing-modal">' +
    '        <svg><path fill-rule="evenodd" d="M8.377 2.001h7.245l1.869.04c.527.042 1.012.131 1.475.352a4 4 0 0 1 1.726 1.588c.259.443.388.919.473 1.44.082.501.133 1.116.195 1.859l.707 8.482.131 2.158c-.001.601-.063 1.158-.295 1.686a4 4 0 0 1-1.759 1.912c-.508.275-1.057.383-1.656.434-.578.049-1.293.049-2.161.049H7.673c-.869 0-1.583 0-2.161-.049-.599-.05-1.148-.159-1.656-.434a4 4 0 0 1-1.759-1.912c-.232-.529-.294-1.085-.295-1.686-.001-.581.059-1.292.131-2.158L2.64 7.28l.195-1.859c.085-.522.214-.998.473-1.44a4 4 0 0 1 1.726-1.588c.463-.221.948-.31 1.475-.352.506-.04 1.123-.04 1.869-.04zm.623 6a1 1 0 1 0-2 0 5 5 0 0 0 5 5 5 5 0 0 0 5-5 1 1 0 1 0-2 0 3 3 0 0 1-3 3 3 3 0 0 1-3-3z"/></svg>' +
    "      </button>" +
    '      <a ng-href="{{$ctrl.productLink}}" class="product__link"></a>' +
    "    </div>" +
    "  </div>" +
    '  <div class="product__inner">' +
    '    <div class="product__head">' +
    '      <a class="product__title" ng-href="{{$ctrl.productLink}}">{{$ctrl.product.name}}</a>' +
    '      <div class="product__price" ng-if="$ctrl.product.price">' +
    //- FOR SALES
    '        <span class="product__full" ng-if="$ctrl.product.seasonal_promo_enabled && !$ctrl.pass">{{$ctrl.product.price | ui8Currency}}</span>' +
    "        <span ng-if='!$ctrl.product.subscription_product'>{{($ctrl.product.seasonal_promo_enabled ? $ctrl.product.seasonal_promo_price : $ctrl.product.price) | ui8Currency}}</span>" +
    "        <span class='product__sale' ng-if='$ctrl.product.subscription_product'>40% off</span>" +
    // '        <span ng-if="$ctrl.product.subscription_product">from {{$ctrl.product.base_subscription_price | ui8Currency}}</span>' +
    // "        <span ng-if='!$ctrl.product.subscription_product'>{{ $ctrl.product.price | ui8Currency}}</span>" +
    "      </div>" +
    '      <div class="product__price" ng-if="!$ctrl.product.price">Free</div>' +
    "    </div>" +
    '    <div class="product__meta">' +
    '      <div class="product__author" ng-if="$ctrl.product.author && !$ctrl.product.is_subscription">' +
    '        <a class="product__avatar" ng-href="/{{$ctrl.product.author.display_name_slug || $ctrl.product.author._id}}/products" ui8-resize-to="{{$ctrl.resizeAvatarTo}}" ui8-image="{{$ctrl.authorAvatar}}"></a>' +
    '        <a ng-href="/{{$ctrl.product.author.display_name_slug || $ctrl.product.author._id}}/products">{{$ctrl.product.author.display_name ? $ctrl.product.author.display_name : ($ctrl.product.author.first_name + " " + $ctrl.product.author.last_name)}}</a>' +
    "      </div>" +
    '      <div class="product__arrow" ng-if="$ctrl.product.category.length && !$ctrl.product.is_subscription"></div>' +
    '      <div class="product__category" ng-if="$ctrl.product.category.length && !$ctrl.product.is_subscription">' +
    '        <a ng-href="/categories/{{$ctrl.product.category[0].slug}}">{{$ctrl.product.category[0].name}}</a>' +
    "      </div>" +
    '      <div class="product__featured" ng-if="$ctrl.product.featured"><span>Featured product</span></div>' +
    "    </div>" +
    "  </div>" +
    '  <download-modal ng-if="$ctrl.showDownloadModal" product="$ctrl.product" display="$ctrl.showDownloadModal"></download-modal>' +
    "</div>",

  controller: function (
    LikeService,
    CartService,
    $element,
    $rootScope,
    $location
  ) {
    // Cart
    this.addToCart = function (e) {
      $rootScope.showSearch = false;
      CartService.addToCart(e);
    };

    // Likes
    this.isLiked = LikeService.isLiked;
    this.unLiked = LikeService.unLiked;
    this.likeProduct = LikeService.toggleLike;
    this.getCountModifier = LikeService.getCountModifier;

    this.showDownloadModal = false;
    this.movedModal = false;

    this.$onInit = function () {
      this.userHasLifetime = window.UI8.userHasLifetime;
      function cacheValue(name, fn) {
        if (window[name] != null) {
          return window[name];
        }

        window[name] = fn();
        return window[name];
      }

      // const isHighDensity = cacheValue("isHighDensity", () => {
      //   if (window.matchMedia) {
      //     if (
      //       window.matchMedia(
      //         "only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)"
      //       ).matches
      //     ) {
      //       return true;
      //     }

      //     if (
      //       window.matchMedia(
      //         "only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)"
      //       ).matches
      //     ) {
      //       return true;
      //     }
      //   }

      //   return window.devicePixelRatio && window.devicePixelRatio > 1.3;
      // });

      // const isRetina = cacheValue("isRetina", () => {
      //   if (window.matchMedia) {
      //     if (
      //       window.matchMedia(
      //         "only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)"
      //       ).matches
      //     ) {
      //       return true;
      //     }

      //     if (
      //       window.matchMedia(
      //         "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)"
      //       ).matches
      //     ) {
      //       return true;
      //     }
      //   }

      //   if (window.devicePixelRatio && window.devicePixelRatio >= 2) {
      //     return true;
      //   }

      //   return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
      // });

      const isWebpSupported = cacheValue("isWebpSupported", () => {
        const elem = document.createElement("canvas");

        if (elem.getContext && elem.getContext("2d")) {
          // was able or not to get WebP representation
          return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
        }

        // old browser, canvas not supported
        return false;
      });

      const resolution = { width: 604, height: 420 };

      const avatarResolution = { width: 40, height: 40 };

      const extension = isWebpSupported ? "webp" : "png";
      const avatarExtension = isWebpSupported ? "png" : "png";

      const S3_IMAGE_SERVER = "https://images.ui8.net";

      if (this.product.has_resized_card_image) {
        const postfix = this.product.card_image_version
          ? `_${this.product.card_image_version}`
          : "";
        this.cardImage = `${S3_IMAGE_SERVER}/product/${this.product._id}/${resolution.width}_${resolution.height}${postfix}.${extension}`;
        this.resizeTo = "";
      } else {
        this.cardImage = this.product.card_image;
        this.resizeTo = `width=${resolution.width},height=${resolution.height}`;
      }

      if (this.product.author.has_resized_avatar) {
        const postfix = this.product.author.avatar_url_version
          ? `_${this.product.author.avatar_url_version}`
          : "";
        this.authorAvatar = `${S3_IMAGE_SERVER}/avatar/${this.product.author._id}/${avatarResolution.width}_${avatarResolution.height}${postfix}.${avatarExtension}`;
        this.resizeAvatarTo = "";
      } else {
        this.authorAvatar =
          this.product.author.avatar_url ||
          "//d1v8dk3ka6obgr.cloudfront.net/v5/assets/global/default_avatar_v2.png";
        this.resizeAvatarTo = `with=${avatarResolution.width},height=${avatarResolution.height}`;
      }

      var productLink = "/";

      if (!this.product.permalink) {
        if (
          this.product.slug !== "all-access-pass" &&
          this.product.author &&
          this.product.author.display_name_slug
        ) {
          productLink += this.product.author.display_name_slug + "/";
        }

        productLink += "products/" + this.product.slug;

        if (this.linkQuery) {
          productLink += this.linkQuery;
        }

        this.productLink = productLink;
      } else {
        this.productLink = this.product.permalink;
      }
    };

    this.toggleDownloadModal = function () {
      this.showDownloadModal = !this.showDownloadModal;

      if (!this.movedModal) {
        this.movedModal = true;

        setTimeout(function () {
          $("download-modal").appendTo("body");
        }, 0);
      }
    };

    this.playVideo = function () {
      var video = $element.find(".prod video")[0];
      if (video) video.play();
    };

    this.attachClickHandler = function () {
      $element.find(".product__meta a").on("click", function () {
        if (!$rootScope.showSearch) return;

        window.scrollLock.enablePageScroll();
        $("body").css("overflow-y", "scroll");

        $rootScope.showSearch = false;
        $location.search("search", null);
      });
    };

    this.$destroy = function () {
      $element.find(".product__meta a").off();
    };
  },

  bindings: {
    product: "<",
    user: "<",
    pass: "<?",
    linkQuery: "<?",
  },
});

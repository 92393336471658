angular.module("UI8.components").component("userProfile", {
  template: `<div class="profile" ng-if="$ctrl.data">
    <div class="profile__header">
      <div class="profile__container container">
        <div class="profile__inner">
          <div class="profile__avatar" ui8-avatar="$ctrl.data.profile"></div>
          <div class="profile__details">
            <div class="profile__line">
              <h1 class="profile__name" ng-if="$ctrl.data.profile.display_name">{{ $ctrl.data.profile.display_name }}</h1>
              <h1 class="profile__name" ng-if="!$ctrl.data.profile.display_name">{{ $ctrl.data.profile.first_name }} {{ $ctrl.data.profile.last_name }}</h1>
              <div class="profile__role profile__badge--admin" ng-if="$ctrl.data.profile.role === 'admin' && $ctrl.data.profile.display_name !== 'UI8'">Admin</div>
              <div class="profile__role" ng-if="$ctrl.data.products">Author</div>
              <div class="profile__role profile__role--follow" ng-if="$ctrl.data.follows_you">Follows you</div>
            </div>
            <div class="profile__bio" ng-bind-html="$ctrl.data.profile.biography"></div>
            <div class="profile__bio" ng-if="$ctrl.user.role === 'admin'">Instagram: {{ $ctrl.data.profile.instagram }}</div>
          </div>
        </div>
        <div class="profile__actions">
          <div class="profile__group ng-cloak">
            <button class="btn btn--stroke" ng-class="{active: $ctrl.tab === 'products'}" ng-if="$ctrl.data.products && $ctrl.data.products.length" ng-click="$ctrl.tab = 'products'">
              Products
              <span>{{ $ctrl.data.productCount || 0 }}</span>
            </button>
            <button class="btn btn--stroke" ng-class="{active: $ctrl.tab === 'followers'}" ng-if="$ctrl.data.profile.followers_sum !== 0" ng-click="$ctrl.tab = 'followers'">
              Followers
              <span>{{ $ctrl.data.profile.followers_sum || 0 }}</span>
            </button>
            <button class="btn btn--stroke" ng-class="{active: $ctrl.tab === 'following'}" ng-if="$ctrl.data.profile.following_sum !== 0" ng-click="$ctrl.tab = 'following'">
              Following
              <span>{{ $ctrl.data.profile.following_sum || 0 }}</span>
            </button>
            <button class="btn btn--stroke" ng-class="{active: $ctrl.tab === 'favorites'}" ng-if="$ctrl.data.profile.favorites_sum !== 0" ng-click="$ctrl.tab = 'favorites'">
              Favorites
              <span>{{ $ctrl.data.profile.favorites_sum || 0 }}</span>
            </button>
          </div>
          <div class="profile__group">
            <a class="btn btn--green" ng-if="!$ctrl.user && $ctrl.data.profile.for_hire" href="/account/signup">Hire</a>
            <button class="btn btn--green" ng-if="$ctrl.user && !$ctrl.ownProfile && $ctrl.data.profile.for_hire" ui8-toggle-class="hire-modal" ui8-track-event="Open Hire Modal">Hire</button>
            <button class="btn" ng-if="$ctrl.user && !$ctrl.ownProfile" ui8-follow-button="{{$ctrl.data.profile._id}}">Follow</button>
            <a class="btn" ng-if="!$ctrl.user && !$ctrl.ownProfile" href="/account/signup">Follow</a>
            <a class="btn btn--stroke" ng-if="$ctrl.user && $ctrl.ownProfile" href="/account/settings">Edit profile</a>
          </div>
        </div>
      </div>
    </div>
    <div class="profile__body">
      <div class="container">
        <div ng-if="$ctrl.tab === 'followers'">
          <div class="profile__message" ng-if="!$ctrl.data.profile.followers_sum">
            {{ $ctrl.ownProfile ? 'You do not' : 'This user does not' }} have any followers yet, here are some of our top designers...
          </div>
          <div class="users" ng-if="$ctrl.followers">
            <div class="users__inner">
              <div class="users__list">
                <list-user ng-repeat="f in $ctrl.followers track by $index" ng-hide="!f" profile="f" user="$ctrl.user"></list-user>
              </div>
            </div>
            <div class="users__foot" ng-if="$ctrl.followers.length < $ctrl.data.profile.followers_sum">
              <button class="btn btn--stroke" ng-click="$ctrl.moreFollowers()">Load more</button>
            </div>
          </div>
        </div>
        <div ng-if="$ctrl.tab === 'following'">
          <div class="profile__message" ng-if="!$ctrl.data.profile.following_sum">
            {{ $ctrl.ownProfile ? 'You are' : 'This user is' }} not following anyone yet, here are some of our top designers...
          </div>
          <div class="users" ng-if="$ctrl.following">
            <div class="users__inner">
              <div class="users__list">
                <list-user ng-repeat="f in $ctrl.following track by $index" profile="f" user="$ctrl.user"></list-user>
              </div>
            </div>
            <div class="users__foot" ng-if="$ctrl.following.length < $ctrl.data.profile.following_sum">
              <button class="btn btn--stroke" ng-click="$ctrl.moreFollowing()">Load more</button>
            </div>
          </div>
        </div>
        <div ng-if="$ctrl.data.favorites && $ctrl.tab === 'favorites'">
          <div class="profile__message" ng-if="!$ctrl.data.favoritesCount">
            {{ $ctrl.ownProfile ? 'You do not' : 'This user does not' }} have any favorites yet, here are some of our most popular products...
          </div>
          <div class="profile__catalog catalog">
            <product-card ng-repeat="product in $ctrl.data.favorites track by $index" product="product" user="$ctrl.user"></product-card>
          </div>
          <div class="profile__foot" ng-if="$ctrl.data.favorites.length < $ctrl.data.favoritesCount">
            <button class="btn" ng-if="!$ctrl.loading" ng-click="$ctrl.moreFavorites()">Load more</button>
            <div class="loader loader--small" ng-if="$ctrl.loading"></div>
          </div>
        </div>
        <div ng-if="$ctrl.data.products && $ctrl.tab === 'products'">
          <div class="profile__catalog catalog">
            <product-card ng-repeat="product in $ctrl.data.products track by $index" product="product" user="$ctrl.user" link-query="'?browse=author'"></product-card>
          </div>
          <div class="profile__foot" ng-if="$ctrl.data.products.length < $ctrl.data.productCount">
            <button class="btn" ng-if="!$ctrl.loading" ng-click="$ctrl.moreProducts()">Load more</button>
            <div class="loader loader--small" ng-if="$ctrl.loading"></div>
          </div>
        </div>
      </div>
    </div>
    <hire-modal ng-if='$ctrl.data.profile.for_hire' author='$ctrl.data.profile' success='$ctrl.hire_message_success'></hire-modal>
  </div>
  <div class="spage" ng-if="!$ctrl.data">
    <div class="spage__background">
      <img src="/img/404.jpg" alt="Oops!">
    </div>
    <div class="spage__container">
      <a href="/" class="spage__logo ui8-logo"></a>
      <div class="spage__title">Oops!</div>
      <div class="spage__content">The user you’re looking for no longer exists.</div>
      <a href="/" class="btn btn--submit">Go to homepage</a>
    </div>
    <div class="spage__copyright">© 2025, Robot Global FZCO / UI8</div>
  </div>`,

  controller: function (UserService, ProductService) {
    var ctrl = this;

    ctrl.$onInit = function () {
      // if (ctrl.data.profile.private_profile) {
      //   ctrl.private = true;
      //   return;
      // }

      // if (ctrl.data.profile.not_found) {
      //   ctrl.not_found = true;
      //   return;
      // }

      if (!ctrl.data.profile.biography)
        ctrl.data.profile.biography = "I haven&apos;t changed my bio yet!";

      ctrl.followersPage = 1;
      ctrl.followingPage = 1;
      ctrl.productsPage = 1;
      ctrl.favoritesPage = 1;

      ctrl.ownProfile = ctrl.user
        ? ctrl.data.profile._id === ctrl.user._id
        : false;

      var urlParams = new URLSearchParams(window.location.search);
      var hashCollection = /#([a-z]+)/i.exec(window.location.hash);
      var hashValue =
        hashCollection && Array.isArray(hashCollection) && hashCollection[1];
      var hasProducts = ctrl.data.products && ctrl.data.products.length;

      switch (hashValue) {
        case "favorites":
          ctrl.tab = "favorites";
          break;
        case "followers":
          ctrl.tab = "followers";
          break;
        case "following":
          ctrl.tab = "following";
          break;
        default:
          ctrl.tab = hasProducts ? "products" : "favorites";
          break;
      }

      if (urlParams.has("success")) {
        ctrl.hire_message_success = parseInt(urlParams.get("success"));
        $("body").addClass("hire-modal");
      }
    };

    ctrl.moreFollowers = function () {
      ctrl.followersPage += 1;
      UserService.followers(ctrl.data.profile._id, ctrl.followersPage).then(
        function (response) {
          ctrl.followers = ctrl.followers.concat(response);
        }
      );
    };

    ctrl.moreFollowing = function () {
      ctrl.followingPage += 1;
      UserService.following(ctrl.data.profile._id, ctrl.followingPage).then(
        function (response) {
          ctrl.following = ctrl.following.concat(response);
        }
      );
    };

    ctrl.moreProducts = function () {
      ctrl.loading = true;
      ctrl.productsPage += 1;
      ProductService.list({
        author: ctrl.data.profile._id,
        sort: "authorDefined",
        page: ctrl.productsPage,
        include_freebies: true,
      })
        .then(function (response) {
          ctrl.loading = false;
          ctrl.data.products = ctrl.data.products.concat(response.products);
          if (response.products.length < 24)
            ctrl.data.productCount = ctrl.data.products.length;
        })
        .catch(function () {
          ctrl.loading = false;
        });
    };

    ctrl.moreFavorites = function () {
      ctrl.loading = true;
      ctrl.favoritesPage += 1;
      UserService.favorites(ctrl.data.profile._id, ctrl.favoritesPage)
        .then(function (response) {
          ctrl.loading = false;
          ctrl.data.favorites = ctrl.data.favorites.concat(response);
          if (response.length < 48)
            ctrl.data.favoritesCount = ctrl.data.favorites.length;
        })
        .catch(function () {
          ctrl.loading = false;
        });
    };
  },

  bindings: {
    data: "<",
    user: "<",
    following: "<",
    followers: "<",
  },
});

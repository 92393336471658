angular.module("UI8.controllers").controller("HeaderController", [
  "$scope",
  "$rootScope",
  "$location",
  "$timeout",
  "PromoService",
  function ($scope, $rootScope, $location, $timeout, PromoService) {
    const browse = document.querySelector(".header__cell--browse"),
      menuInner = document.querySelector(".menu__inner");

    const applyOverlayMask = (e) => {
      if (browse.classList.contains("active")) {
        const overlayEl = e.currentTarget;
        const x = e.clientX - menuInner.offsetLeft;
        const y = e.clientY - menuInner.offsetTop;

        overlayEl.style = `--opacity: 1; --x: ${x}px; --y:${y}px;`;
      }
    };

    document.body.addEventListener("pointermove", applyOverlayMask);

    const headAap40 = document.querySelector(".header__head--aap40");
    if (headAap40) {
      headAap40.addEventListener("click", () => {
        PromoService.applyPromo("aap40").then((promo) => {
          UI8.promo = promo;
          UI8.isRenewal = true;
          var event = new Event("promo-updated");
          document.body.dispatchEvent(event);
        });
      });
    }

    const menuSpace = document.querySelector(".menu__space");
    if (menuSpace) {
      menuSpace.addEventListener("click", () => {
        window.dataLayer.push({
          event: "banner_click",
          element: "menu_banner",
        });
      });
    }

    $scope.openSearchWithAppFilter = function (val) {
      val = val || sessionStorage.getItem("search:app-filter");

      $rootScope.showSearch = true;
      $rootScope.appFilter = val || "Any Platform";
      sessionStorage.setItem("search:app-filter", $rootScope.appFilter);
    };
  },
]);

angular.module("UI8.components").component("accountSignup", {
  template: `<div class="login">
      <div class="login__container">
        <div class="login__inner">
          <a href="/" class="login__logo">
            <svg width="80" height="80">
              <rect width="80" height="80" fill="#181818" fill-rule="nonzero" rx="40"></rect>
              <path fill="#2d68ff" d="M57.5 36.9979c4.1421 0 7.5-3.3575 7.5-7.4991s-3.3579-7.499-7.5-7.499-7.5 3.3574-7.5 7.499 3.3579 7.4991 7.5 7.4991z"></path><path fill="#f8f8f8" d="M58 40.9528l-1 .0448c-6.0751 0-11-4.9242-11-10.9986 0-2.0074.5378-3.8891 1.4772-5.5091l-4.3235-2.3517c-1.9664-1.0697-4.341-1.0697-6.3074 0l-11.4 6.201C23.3222 29.4946 22 31.719 22 34.1369v11.7359c0 2.418 1.3222 4.6424 3.4463 5.7977l11.4 6.2011c1.9664 1.0696 4.341 1.0696 6.3074 0l11.4-6.2011C56.6778 50.5152 58 48.2908 58 45.8728v-4.92z"></path>
            </svg>
          </a>
          <h1 class="login__title">Create new account</h1>
          <div class="login__form" ng-init="passwordHidden = true">
            <div class="field">
              <div class="field__label">Full name</div>
              <input class="field__input" ng-class="{error: $ctrl.registerFullNameError}" placeholder="Jamie Davis" ng-keydown="$ctrl.registerFullNameError = false" type="text" name="full_name" ng-model="$ctrl.account.full_name" required>
            </div>
            <div class="field">
              <div class="field__label">Email</div>
              <input class="field__input" ng-class="{error: $ctrl.registerEmailError}" placeholder="designer@example.com" ng-keydown="$ctrl.registerEmailError = false" type="email" name="email" ng-model="$ctrl.account.email" ng-pattern="/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$/i" ng-disabled="$ctrl.disabled" required>
            </div>
            <div class="field">
              <div class="field__label">Password</div>
              <input class="field__input" ng-class="{error: $ctrl.registerPasswordError}" placeholder="password" ng-keydown="$ctrl.registerPasswordError = false" type="{{passwordHidden ? 'password' : 'text'}}" name="password" ng-model="$ctrl.account.password" ng-disabled="$ctrl.disabled" maxlength="40" required>
                <button ng-click="passwordHidden = !passwordHidden" class="field__action">
                <svg ng-if="!passwordHidden" width="24" height="24"><path d="M12 4c4.517 0 7.994 2.902 10.426 6.753h0l.119.191.078.138.063.132a1.88 1.88 0 0 1 .112.339 2.16 2.16 0 0 1 0 .893 1.88 1.88 0 0 1-.112.339h0l-.063.132-.078.138-.119.192C19.994 17.098 16.517 20 12 20s-7.994-2.902-10.426-6.753h0l-.161-.264-.067-.129-.031-.068a1.88 1.88 0 0 1-.112-.339 2.16 2.16 0 0 1 0-.894 1.88 1.88 0 0 1 .112-.339 2.12 2.12 0 0 1 .063-.132h0l.078-.138.303-.479C4.181 6.759 7.597 4 12 4zm0 2c-3.646 0-6.633 2.494-8.735 5.821h0l-.111.178.293.463C5.538 15.647 8.459 18 12 18c3.646 0 6.634-2.494 8.735-5.821h0l.109-.181-.291-.46C18.463 8.353 15.542 6 12 6zm0 2a4 4 0 1 1 0 8 4 4 0 1 1 0-8zm0 2a2 2 0 1 0 0 4 2 2 0 1 0 0-4z"></path></svg>
                <svg ng-if="passwordHidden" width="24" height="24"><path d="M3.613 2.21l.094.083 3.652 3.649c.05.041.096.087.138.139l10.414 10.413c.058.046.112.1.16.16l3.636 3.639a1 1 0 0 1-1.32 1.497l-.094-.083-3.154-3.153C15.548 19.486 13.831 20 12 20c-4.404 0-7.819-2.759-10.241-6.466l-.303-.478-.078-.138a2.12 2.12 0 0 1-.063-.132 1.88 1.88 0 0 1-.112-.339 2.16 2.16 0 0 1 0-.894c.067-.293.141-.436.373-.802.947-1.499 2.153-2.952 3.618-4.143L2.293 3.707a1 1 0 0 1 1.32-1.497zm-.102 9.234l-.355.552V12l.111.179C5.367 15.506 8.355 18 12 18c1.282 0 2.504-.32 3.667-.918l-1.635-1.636A3.99 3.99 0 0 1 12 16a4 4 0 0 1-4-4 3.99 3.99 0 0 1 .554-2.031L6.617 8.032c-1.227.964-2.27 2.163-3.106 3.412zM12 4c4.517 0 7.994 2.902 10.426 6.753l.119.191.078.138.063.132a1.88 1.88 0 0 1 .112.339 2.16 2.16 0 0 1 0 .895c-.067.293-.142.436-.373.802a18.39 18.39 0 0 1-1.441 1.973 1 1 0 1 1-1.533-1.284c.397-.475.751-.951 1.061-1.414l.335-.52-.001-.007-.109-.177C18.634 8.494 15.646 6 12 6a7.59 7.59 0 0 0-1.111.082 1 1 0 1 1-.292-1.979C11.059 4.035 11.527 4 12 4zm-2 8a2 2 0 0 0 2 2c.178 0 .352-.023.518-.068l-2.451-2.45A2.01 2.01 0 0 0 10 12z"></path></svg>
              </button>
            </div>
            <button class="btn btn--submit" ng-if="!$ctrl.loading" ng-click="$ctrl.submitRegistration()">Sign up</button>
            <div class="loader" ng-if="$ctrl.loading"></div>
            <a href="/auth/google" class="btn btn--submit btn--google" ng-click="$ctrl.trackRegistration()">
              <svg width="24" height="24" fill="none">
                <path d="M22.5005 12.2331c0-.8633-.0714-1.4933-.2261-2.1467H12.2148v3.8966h5.9048c-.119.9684-.7619 2.4267-2.1905 3.4067l-.02.1305 3.1806 2.4147.2204.0215c2.0237-1.8317 3.1904-4.5267 3.1904-7.7233z" fill="#4285f4"></path>
                <path d="M12.214 22.5001c2.8928 0 5.3213-.9334 7.0952-2.5434l-3.381-2.5667c-.9047.6183-2.119 1.05-3.7142 1.05-2.83331 0-5.23804-1.8317-6.09526-4.3633l-.12565.0104-3.30726 2.5084-.04325.1178c1.76188 3.43 5.38092 5.7868 9.57142 5.7868z" fill="#34a853"></path>
                <path d="M6.12095 14.0767c-.22619-.6533-.35709-1.3534-.35709-2.0767s.1309-1.4233.34519-2.07663l-.00599-.13914L2.75435 7.2356l-.10957.05107C1.91862 8.71002 1.50195 10.3084 1.50195 12s.41667 3.29 1.14283 4.7133l3.47617-2.6366z" fill="#fbbc05"></path><path d="M12.2141 5.55997c2.0118 0 3.3689.85166 4.1428 1.56338L19.3807 4.23c-1.8571-1.69166-4.2738-2.73-7.1666-2.73-4.19057 0-7.80963 2.35665-9.57152 5.78662l3.46428 2.6367c.86912-2.53166 3.27387-4.36335 6.10724-4.36335z" fill="#eb4335"></path>
              </svg>Sign up with Google</a>
          </div>
          <div class="login__hint">Already have an account? <a href="/account/login">Log in</a></div>
        </div>
      </div>
      <div class="login__copyright">© 2025, Robot Global FZCO / UI8</div>
      <a class="login__space" href="https://studio.ui8.net" ng-click="$ctrl.trackSignupBanner()" target="_blank">
        <img src="/img/banners/auth-background.avif" alt="UI8 Studio">
        <img src="/img/banners/auth-top.avif" alt="UI8 Studio">
        <img src="/img/banners/auth-bottom.avif" alt="UI8 Studio">
      </a>
    </div>`,

  controller: function ($http, $location, FlashService) {
    var ctrl = this;

    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    function handleError(error) {
      FlashService.set("error", error);
    }

    function handleEnter(e) {
      if (e.key === "Enter") {
        ctrl.submitRegistration();
      }
    }

    ctrl.$onInit = function () {
      ctrl.next = $location.path();
      ctrl.account = ctrl.account || {};

      $(document.body).on("keydown", handleEnter);
    };

    ctrl.$onDestroy = function () {
      $(document.body).off("keydown");
    };

    ctrl.trackSignupBanner = function () {
      window.dataLayer.push({
        event: "banner_click",
        element: "signup_banner",
      });
    };

    ctrl.trackRegistration = function () {
      // google tracking
      window.dataLayer.push({
        event: "formSubmission",
        formAction: "form submit - sign up",
      });
    };

    ctrl.submitRegistration = function () {
      if (!ctrl.account.full_name) return (ctrl.registerFullNameError = true);
      if (!ctrl.account.email || !validateEmail(ctrl.account.email))
        return (ctrl.registerEmailError = true);
      if (!ctrl.account.password) return (ctrl.registerPasswordError = true);

      // Get recaptcha token
      var rc = grecaptcha || window.grecaptcha;
      var rcKey = UI8.recaptchaSiteKey;
      if (!rc || !rcKey)
        FlashService.set(
          "error",
          "Unable to process registration. Please try again later."
        );

      ctrl.loading = true;

      rc.execute(rcKey, { action: "registration" })
        .then(function (token) {
          // Submit the form
          ctrl.trackRegistration();

          var regexPattern = /\s+/g;
          var fullName = ctrl.account.full_name
            .trim()
            .replace(regexPattern, " ")
            .split(" ");

          ctrl.account.first_name = fullName[0];
          ctrl.account.last_name = fullName[1] || "";

          $http
            .post("/account/register?json=1", {
              first_name: ctrl.account.first_name,
              last_name: ctrl.account.last_name,
              email: ctrl.account.email,
              password: ctrl.account.password,
              rcToken: token,
            })
            .then(
              function (response) {
                if (response.data.error) {
                  ctrl.loading = false;
                  return handleError(response.data.error);
                }

                window.location.replace("/");
              },
              function (error) {
                ctrl.loading = false;
                handleError(
                  error.data.error ||
                    error.error ||
                    "Unable to register. Please try again."
                );
              }
            );
        })
        .catch(function (error) {
          ctrl.loading = false;
          console.log(error);
          FlashService.set(
            "error",
            "Unable to process registration. Please try again later."
          );
        });
    };
  },
});
